<template>
  <main class="md:p-4 max-w-4xl">
    <sqr-page-header :title="broker.name" class="p-4"/>
    <section class="p-4 bg-white mx-auto max-w-4xl md:rounded md:shadow">
      <div class="container">
        <div class="columns is-multiline">
          <div
            class="links column"
          >
            <h2 class="title is-5">{{$t('broker_customers')}}</h2>
            <router-link :to="{ name: 'relations', params: { bid } }">
              <div>{{ $t('broker_relations') }}</div>
              <div class="link-desc">{{ $t('broker_relations_help') }}</div>
            </router-link>
            <router-link :to="{ name: 'birthdays', params: { bid } }">
              <div>{{ $t('broker_birthdays') }}</div>
              <div class="link-desc">{{ $t('broker_birthdays_help') }}</div>
            </router-link>
            <router-link :to="{ name: 'policies', params: { bid } }">
              <div>{{ $t('broker_policies') }}</div>
              <div class="link-desc">{{ $t('broker_policies_help') }}</div>
            </router-link>
          </div>
          <div
            class="links column"
          >
            <h2 class="title is-5">{{$t('broker_products')}}</h2>
            <router-link :to="{ name: 'insurers', params: { bid } }">
              <div>{{ $t('broker_insurers') }}</div>
              <div class="link-desc">{{ $t('broker_insurers_help') }}</div>
            </router-link>
            <router-link :to="{ name: 'branches', params: { bid } }">
              <div>{{ $t('broker_branches') }}</div>
              <div class="link-desc">{{ $t('broker_branches_help') }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="postcss" scoped>
.links {
  @apply -mx-2;
}
.links h2 {
  @apply px-2 pb-0 mb-2;
}
.links a {
  @apply block p-2;
}
.links a:hover {
  @apply bg-gray-100;
}
.link-desc {
  @apply text-xs text-gray-700;
}
</style>

<script>
import broker from './broker.js';
export default {
  name: 'Broker',
  mixins: [broker]
};
</script>
